const binding = { modules: {}, dataActions: {} };

    (binding.modules['mirageworks'] = {
        c: () => require('partner/themes/mirageworks/mirageworks.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['active-image','b2b-requests-status','business-organization-list','buybox','cart-icon','cart','checkout-delivery-options','checkout-section-container','checkout','content-block','header','image-list','navigation-menu','order-confirmation','order-template-list','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container','search','sign-in','store-selector','text-block','tile-list-item','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'mirageworks',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'mirageworks.theme.settings.json',
        md: 'src/themes/mirageworks'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };